import { enhancePrisma } from "blitz"
import { PrismaClient } from "@prisma/client"

const EnhancedPrismaClient = enhancePrisma(PrismaClient)

const db = new EnhancedPrismaClient()

export * from "@prisma/client"

export default db
