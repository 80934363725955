import Icon from "@ant-design/icons"
import GoogleColorfulSvg from "@Public/svg/icons/google-colorful.svg"
import LinkedInSvg from "@Public/svg/icons/linkedin.svg"
import AppleBlackSvg from "@Public/svg/icons/apple.svg"
import { cn } from "@Utils/theme"

interface SSOButtonProps extends React.HTMLAttributes<HTMLAnchorElement> {
  href: string
  icon: React.ReactNode
}

export const SSOButton = ({ href, icon, className, ...props }: SSOButtonProps) => {
  return (
    <a
      className={cn(
        "group/button w-[4rem] h-[4rem] rounded-full text-[2rem] flex justify-center items-center hover:!bg-primary-400/20",
        className
      )}
      href={href}
      {...props}
    >
      {icon}
    </a>
  )
}

export interface SSOButtonsProps {
  className?: string
}

const SSOButtons: React.FC<SSOButtonsProps> = ({ className }) => {
  return (
    <div className={cn("w-full flex items-center justify-center gap-[2.4rem]", className)}>
      <SSOButton
        data-testid="continue-with-linkedin-button"
        href={
          process.env.NEXT_PUBLIC_IDM_AUTH_URL +
          "/linkedin?apiKey=" +
          process.env.NEXT_PUBLIC_IDM_API_KEY
        }
        icon={
          <Icon
            className="text-[#0B65C2] transition duration-200 ease-in-out"
            component={LinkedInSvg}
          />
        }
      />
      <SSOButton
        data-testid="continue-with-google-button"
        href={
          process.env.NEXT_PUBLIC_IDM_AUTH_URL +
          "/google?apiKey=" +
          process.env.NEXT_PUBLIC_IDM_API_KEY
        }
        icon={<Icon component={GoogleColorfulSvg} />}
      />
      <SSOButton
        data-testid="continue-with-apple-button"
        href={
          process.env.NEXT_PUBLIC_IDM_AUTH_URL +
          "/apple?apiKey=" +
          process.env.NEXT_PUBLIC_IDM_API_KEY
        }
        icon={<Icon component={AppleBlackSvg} />}
      />
    </div>
  )
}

export default SSOButtons
