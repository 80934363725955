import { default as NextLink, LinkProps as NextLinkProps } from "next/link"
import React from "react"
import { cn } from "@Utils/theme"

interface LinkProps
  extends Omit<NextLinkProps, "href">,
    Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, "href"> {
  testID?: string
  href?: NextLinkProps["href"]
  secondary?: boolean
}

const Link = ({
  testID,
  className,
  style,
  children,
  secondary,
  href,
  target,
  ...props
}: LinkProps): JSX.Element => {
  if (!href) {
    return (
      <span
        data-testid={testID}
        role="link"
        tabIndex={0}
        className={cn("link", secondary && "!text-blue-80", className)}
        {...props}
      >
        {children}
      </span>
    )
  }

  if (typeof href === "string" && (href.startsWith("http") || target === "_blank")) {
    return (
      <a
        data-testid={testID}
        className={cn("link", secondary && "!text-blue-80", className)}
        href={href}
        target={target}
        {...props}
      >
        {children}
      </a>
    )
  }

  return (
    <NextLink
      data-testid={testID}
      className={cn("link", secondary && "!text-blue-80", className)}
      href={href}
      target={target}
      {...props}
    >
      {children}
    </NextLink>
  )
}

export default Link
