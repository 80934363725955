import Logo from "@Components/Header/Logo"
import Link from "@Components/ui/Link"
import { cn } from "@Utils/theme"
import React from "react"
import SSOButtons from "./SSOButtons"
import { useRouter } from "next/router"

type Props = React.PropsWithChildren<{
  rootClassName?: string
  containerClassName?: string
  contentClassName?: string
  isSuccess?: boolean
  onSuccessComponent?: React.ReactNode
  title?: string | React.ReactNode
  description?: string | React.ReactNode
  ssoButtons?: boolean
}>

export const AuthFormCard: React.FC<Props> = ({
  rootClassName,
  containerClassName,
  contentClassName,
  isSuccess = false,
  onSuccessComponent,
  title,
  description,
  ssoButtons,
  children,
}) => {
  const router = useRouter()

  return (
    <div className={cn("w-full flex flex-col items-center justify-center", rootClassName)}>
      <div
        className={cn(
          "flex flex-col gap-[2.4rem] items-center justify-between w-full min-h-[100svh] px-[1.6rem] py-[1.6rem] md:px-[2.95rem] md:py-[2.4rem]",
          containerClassName
        )}
      >
        <Logo onClick={() => router.push("/")} />
        <div
          className={cn(
            "bg-white border  border-light-400 rounded-card p-[2.4rem] md:p-[4rem] w-full opacity-0 animate-smoothFadeInUp",
            contentClassName
          )}
        >
          {title ? (
            <div className="flex flex-col items-center justify-center gap-[0.8rem]">
              <div
                role="heading"
                aria-level={1}
                className="heading-5 md:heading-1 text-new-primary-400 font-bold text-center"
              >
                {title}
              </div>
              {description ? description : null}
            </div>
          ) : null}
          {isSuccess ? onSuccessComponent : children}
          {ssoButtons ? (
            <>
              <div className="relative h-[1.6rem] my-[2.4rem] md:my-[4rem]">
                <div className="absolute top-1/2 -translate-y-1/2 h-[1px] w-full bg-gray-200"></div>
                <div className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 bg-white px-[2rem] py-[1rem] body-small md:body-medium uppercase text-nowrap font-semibold text-new-primary-600">
                  OR CONTINUE WITH
                </div>
              </div>
              <SSOButtons />
            </>
          ) : null}
        </div>
        <div className="flex flex-row items-center justify-center gap-[2.4rem] md:gap-[3rem]">
          <Link
            href="/privacy-policy"
            target="_blank"
            className="font-montserrat text-[1.2rem] !text-new-primary-400 hover:!text-new-primary-300 !font-medium"
          >
            Privacy Policy
          </Link>
          <Link
            href="/terms-of-service"
            target="_blank"
            className="font-montserrat text-[1.2rem] !text-new-primary-400 hover:!text-new-primary-300 !font-medium"
          >
            Terms of Service
          </Link>
        </div>
      </div>
    </div>
  )
}

export default AuthFormCard
