import { CalendarConnectionCallbackStatus } from "./types"

export const calendarConnectionCallbackStatusToMessage = {
  [CalendarConnectionCallbackStatus.ErrorUserNotFound]:
    "We were unable to find your account. Please contact BrokerBlocks support.",
  [CalendarConnectionCallbackStatus.ErrorEmailsDoNotMatch]:
    "It seems that you are trying to connect a different calendar than the one you already used with BrokerBlocks application. Unfortunatly we do not support this feature yet. Please connect your original calendar.",
  [CalendarConnectionCallbackStatus.ErrorMissingScopes]:
    "It seems that you have not granted all necessary permissions to BrokerBlocks application. Please try again.",
  [CalendarConnectionCallbackStatus.ErrorUnknown]:
    "We were unable to connect your google calendar. Please contact BrokerBlocks support.",
  [CalendarConnectionCallbackStatus.Ok]:
    "We have successfully started process of your google account synchronization.",
}
