import { cn } from "@Utils/theme"

export interface FormRowProps extends React.HTMLAttributes<HTMLDivElement> {}

const FormRow = ({ className, children, ...props }: FormRowProps) => {
  return (
    <div className={cn("flex flex-row gap-[2rem]", className)} {...props}>
      {children}
    </div>
  )
}

export default FormRow
