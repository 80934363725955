import { cn } from "@Utils/theme"
import { useToasts } from "./ToastContext"
import ToastItem from "./ToastItem"

const ToastHost = () => {
  const toasts = useToasts()

  return (
    <div
      className={cn(
        "z-[9999] fixed top-0 inset-x-0 flex justify-center mt-[2.6rem]",
        !toasts.length && "hidden"
      )}
    >
      <div className="inline-block transform space-y-2 mx-[2rem]">
        {toasts.map((toast) => (
          <ToastItem key={toast.id} {...toast} />
        ))}
      </div>
    </div>
  )
}

export default ToastHost
