import Head from "next/head"
import { BlitzLayout } from "@blitzjs/next"
import { IntercomProvider } from "./IntercomProvider"
import Container from "./Container"

interface Props {
  title?: string
}

const Layout: BlitzLayout<Props> = ({ title, children }) => {
  return (
    <>
      <Head>
        <title>{title || "BrokerBlocks"}</title>
      </Head>
      <IntercomProvider>
        <Container>{children}</Container>
      </IntercomProvider>
    </>
  )
}

export default Layout
